.dmt__contact {
    display: block;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.dmt__contact-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;
    padding: 2rem;
}

.dmt__contact-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.dmt__contact-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.dmt__contact-content input{
    width: 100%;
    height: 40px;
    padding: 0 1rem;
    margin: 1rem 0;
    border-radius: 2rem;
    border: none;
    outline: none;
}
.dmt__contact-content textarea {
    width: 100%;
    height: 100px;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 2rem;
    border: none;
    outline: none;
    resize: none;
    max-width: 100%;
}


.dmt__contact-content button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .dmt__contact {
        flex-direction: column;
        padding: 0.1rem;
    }

    .dmt__contact-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .dmt__contact {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .dmt__contact-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .dmt__contact-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}
